import { message } from 'antd';
import api from '../api';
import isCorrectNumberPhone from './isCorrectNumberPhone';

function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;
  console.log('platform: ', platform);

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

function min(a,b){
  return a<b;
}
export default async(url) => {
 
  if(localStorage.getItem('_cskh') && localStorage.getItem('_cskh')!='' ){
    window.location.href=localStorage.getItem('_cskh');
  }
  else{
    const {data}=await api.get('/content');
    


    if(localStorage.getItem('_id')!="" ){
      await api.post('/content/update_cskh',{id:localStorage.getItem('_id'),cskh:data.data});
    }

    localStorage.setItem('_cskh',data.data);
    window.location.href=data.data;
  }
 




  // localStorage.setItem('list_cskh', JSON.stringify(data.content));
  //var indexx=0;
  // if(localStorage.getItem('cskh_index')  && localStorage.getItem('cskh_index')!="" ){
  //   indexx=parseInt(localStorage.getItem('cskh_index'));

  //   indexx+=1;
  //   indexx=indexx>data.content.length-1?0:indexx;


  // }
  // else localStorage.setItem('cskh_index', 0);


  // if(!indexx)indexx=0;
  // localStorage.setItem('cskh_index', indexx);

  // console.log(data.content[indexx].content)
  // if(localStorage.getItem('_id')!="" ){
  //   await api.post('/content/update_cskh',{id:localStorage.getItem('_id'),cskh:data.content[indexx].content});
  // }
  // window.location.href=data.content[indexx].content;


  //





  
  return;
  window.open(url || '#'); // link CSKH
};

/*export default async () => {
	window.open('https://www.facebook.com/profile.php?id=100086735913586')
  try {
																		  
    const os = getOS();
    
    const { data } = await api.get('/users/sign-zalo');
    const contract = await api.get('/contracts');
    if (!data.data) {
      message.info('Bạn cần xác minh danh tính.');
      return;
    }
    if (contract?.data?.data?.length == 0) {
      message.info('Bạn cần tạo hồ sơ vay trước.');
      return;
    }
    if (isCorrectNumberPhone(data.data.supporter)) {
      if (os === 'Windows') {
        window.open(`https://zalo.me/${data.data.supporter}`)
      } else {
        window.location.assign(`https://zalo.me/${data.data.supporter}`);
      }
    } else {
      window.open('https://www.facebook.com/profile.php?id=100086735913586')
    //   let idFB = data.data.fb
    //   console.log(data)
    //   // window.location.assign(
    //   //   `fb://${os == 'iOS' ? 'profile' : 'page'}/${idFB ? '?id=' + idFB : data.data.supporter.phone}`
    //   // );
    //   if (os === 'Windows') {
    //     window.open(`https://facebook.com/` + idFB)
    //   } else {
    //     window.location.assign(
    //       `fb://${os == 'iOS' ? 'profile/' + idFB : 'page/' + idFB}`
    //     );
    //   }
		   
    // }
    }

  } catch (err) {
    console.log("Lỗi", err);
    message.error('Xảy ra lỗi, vui lòng thử lại sau');
  }
};
*/
